import React, { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { QuickActions } from "../../Components/QuickActions/QuickActions";
import ReauthenticateUser from "../../Components/ReauthenticateUser/ReauthenticateUser";
import { TopNavBar } from "../../Components/TopNavBar/TopNavBar";
import { UserRole } from "../../Components/UserRole/UserRole";
import { DataContext } from "../../Context/DataProvider";
// import { onMessageListener } from "../../firebase.init";
const Borrower = React.lazy(() => import("../Borrower/Borrower"));
const Investment = React.lazy(() => import("../InvestorFlow"));
// const messaging = getMessaging(app);

export const Home = () => {
  const { metaData, setRefetchMetaVersion } = useContext(DataContext);
  const [loanStatus, setLoanStatus] = useState("started");
  const { employeeProfile, employeePersonalDetails } = metaData || {};
  const { creditScore, contactNumber, requireCredentialRefresher } =
    employeeProfile || {};
  const lowCredit = (creditScore >= 300 && creditScore < 600) || false;
  const [selectedTab, setSelectedTab] = useState("");

  const navigate = useNavigate();
  // store device token
  // useEffect(() => {
  //   // check if token is already available in session
  //   const tokenAvailable = sessionStorage.getItem("deviceToken");
  //   if (tokenAvailable) {
  //     return;
  //   }
  //   getToken(messaging, {
  //     vapidKey: process.env.REACT_APP_vapidKey,
  //   }).then((currentToken) => {
  //     if (currentToken) {
  //       axiosInstance
  //         .post("/general/storeDeviceToken", {
  //           fcmToken: currentToken,
  //         })
  //         .then((res) => {
  //           if (res?.data?.status === "success") {
  //             sessionStorage.setItem("deviceToken", true);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } else {
  //       console.log("Can not get token");
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const status = metaData?.employeeLoans?.loanStatus?.toLowerCase();

    if (status === "completed") {
      setLoanStatus("started");
    } else {
      setLoanStatus(status ? status : "started");
    }
    // setLoanStatus("started");

    // push notification in live app
    // onMessageListener()
    //   .then((payload) => {
    //     const notification = payload.notification;
    //     toast(notification.body);
    //   })
    //   .catch((err) => console.log("failed: ", err));
  }, [metaData?.employeeLoans]);

  useEffect(() => {
    const activeTab = sessionStorage.getItem("keeper_tab");
    if (activeTab) {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab("borrower");
    }
  }, []);

  // checking mutualFundEligibility null value
  // const isMutualFundEligible = Object.keys(
  //   metaData?.mutualFundEligibility
  // )?.every((key) => metaData?.mutualFundEligibility[key] === null);
  // const isFlowDrawerOpen = sessionStorage.getItem("flow_drawer_close");

  return (
    <>
      <div className="relative">
        <div className="fixed top-0 w-full md:w-[412px] z-50">
          <TopNavBar employeeProfile={metaData?.employeeProfile} />
        </div>
        <div className="pt-20">
          <UserRole
            employeePersonalDetails={metaData?.employeePersonalDetails}
          />
          {selectedTab === "borrower" ? (
            <Suspense fallback={<Loader height="full" message="" />}>
              <Borrower
                loanStatus={loanStatus}
                metaData={metaData}
                setRefetchMetaVersion={setRefetchMetaVersion}
                creditScore={creditScore}
                lowCredit={lowCredit}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<Loader height="full" message="" />}>
              <Investment />
            </Suspense>
          )}
          <QuickActions />
        </div>
        {/* <div className="fixed bottom-0 w-full md:w-[412px] z-50">
          <Tabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            loanImage={loanImage}
            investImage={investImage}
          />
        </div> */}

        <ReauthenticateUser
          requireCredential={requireCredentialRefresher == 0 ? false : true}
          organisation={employeePersonalDetails?.organisation}
        />
      </div>
      {/* {isMutualFundEligible && !isFlowDrawerOpen ? (
        <LAMPFlow contactNumber={contactNumber} />
      ) : undefined} */}
    </>
  );
};
