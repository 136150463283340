import { Disclosure, Transition } from "@headlessui/react";
import { useContext, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import faqIcons from "../../Assets/faq-icon.png";
import logoutIcon from "../../Assets/logout-robot.png";
import policiesIcon from "../../Assets/policies-icon.png";
import { DataContext } from "../../Context/DataProvider";
import { LogoutConfirmation } from "../LogoutConfirmation/LogoutConfirmation";

const actions = [
  // {
  //   icon: historyIcons,
  //   title: "My Wallet",
  //   description: "Check all your past payments here",
  //   href: "/my-wallet",
  // },
  // {
  //   icon: closedDrawIcons,
  //   title: "Closed draws",
  //   description: "Access your fully paid draws",
  //   href: "/",
  // },
  {
    icon: faqIcons,
    title: "FAQs",
    description: "Frequently asked questions",
    href: "/faq",
  },
  // {
  //   icon: supportIcons,
  //   title: "Support",
  //   description: "Reach out to us for any help",
  //   href: "/",
  // },
];

const policiesLink = [
  { title: "terms & conditions", href: "/terms" },
  { title: "privacy policy", href: "/privacy-policy" },
  // { title: "refund policy", href: "/refund-policy" },
];
export const QuickActions = () => {
  const { setUserInfo, setAuthToken } = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const handleLogOut = () => {
    setAuthToken(null);
    setUserInfo(null);
    localStorage.removeItem("keeperAuthToken");
  };
  return (
    <>
      <div className="px-4 mb-10 mt-3 space-y-1">
        <h2 className="text-lg font-semibold tracking-wider text-[#A3A3A3] uppercase">
          Quick Actions
        </h2>
        <div className="grid grid-cols-1 divide-y pb-3">
          {actions?.map((action) => (
            <ActionCard key={action?.title} action={action} />
          ))}
        </div>
        <div className="border-t border-b">
          <Disclosure>
            {({ open }) => (
              <>
                <div className="flex justify-between items-center">
                  <Disclosure.Button className={"w-full"}>
                    <div className="text-[#8A8A8A] flex gap-4 py-5 items-center text-left">
                      <div className="w-11 h-11 rounded-full">
                        <img
                          src={policiesIcon}
                          className="h-full w-full"
                          alt=""
                        />
                      </div>
                      <div className="">
                        <h2 className="text-lg font-semibold">Policies</h2>
                        <h4 className="text-xs">
                          T&C, privacy and refund policies
                        </h4>
                      </div>
                    </div>
                  </Disclosure.Button>
                  <span>
                    <BsChevronDown
                      className={`h-7 w-7 ${
                        open ? "rotate-180 transform" : ""
                      }`}
                    />
                  </span>
                </div>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel>
                    <div className="pb-5 ml-[60px] space-y-2">
                      {policiesLink?.map(({ title, href }) => (
                        <Link
                          key={title}
                          to={href}
                          className="block underline underline-offset-2 text-primary"
                        >
                          {title}
                        </Link>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
        <div
          className="text-[#8A8A8A] flex gap-4 pb-5 pt-3 items-center cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div className="w-11 h-11 rounded-full">
            <img src={logoutIcon} className="h-full w-full" alt="" />
          </div>
          <div className="">
            <h2 className="text-lg font-semibold">Logout</h2>
            <h4 className="text-xs">Logout from keeper</h4>
          </div>
        </div>
      </div>
      <LogoutConfirmation
        action={handleLogOut}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

const ActionCard = ({ action }) => {
  const { icon, title, description, href } = action || {};
  return (
    <>
      <Link
        to={href}
        className="text-[#8A8A8A] flex gap-4 py-5 last:pb-0 items-center"
      >
        <div className="w-11 h-11 rounded-full">
          <img src={icon} className="h-full w-full" alt="" />
        </div>
        <div className="">
          <h2 className="text-lg font-semibold">{title}</h2>
          <h4 className="text-xs">{description}</h4>
        </div>
      </Link>
    </>
  );
};
