import keeperLogo from "../../../Assets/logo.png";
export default function HRMSPageBanner({ status, selectedEmployer }) {
  const { hrmsLogo, logo } = selectedEmployer || {};
  return (
    <>
      <div
        className={`space-y-3 ${
          status === "loading" ? "mb-10" : "-mt-8 mb-10"
        }`}
      >
        <div className="flex justify-center items-center gap-7">
          <div className="w-20">
            <img src={logo} className="w-full" alt="" />
          </div>
          <div className="h-20 w-[2px] bg-gray-300"></div>
          <div className="w-20">
            <img src={hrmsLogo} className="w-full" alt="" />
          </div>
        </div>
        <div className="flex justify-center items-center -ml-2">
          <span>powered by</span>{" "}
          <div className="w-6 ml-[8px] mr-[10px]">
            <img src={keeperLogo} className="w-full" alt="" />
          </div>
          <span
            className="text-2xl font-bold text-gray-800"
            style={{ fontFamily: "sans-serif" }}
          >
            Keeper
          </span>
        </div>
      </div>
    </>
  );
}
