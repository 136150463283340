import React, { useEffect, useState } from "react";

const TipsDisplay = ({ tipsList, isDisplay }) => {
  const [tipIndex, setTipIndex] = useState(0);
  const [currentTip, setCurrentTip] = useState(tipsList[0]);

  // Display tips in a loop with a 3-second interval
  useEffect(() => {
    if (isDisplay) {
      const tipTimer = setInterval(() => {
        setTipIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % tipsList.length;
          setCurrentTip(tipsList[newIndex]);
          return newIndex;
        });
      }, 3000);

      return () => clearInterval(tipTimer);
    }
  }, [isDisplay, tipsList]);

  return <span>{currentTip}</span>;
};

export default TipsDisplay;
