import { useContext, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import warningImage from "../../Assets/warning.png";
import { LogoutConfirmation } from "../../Components/LogoutConfirmation/LogoutConfirmation";
import { PrimaryButton } from "../../Components/PrimaryButton/PrimaryButton";
import { DataContext } from "../../Context/DataProvider";

export function ErrorPage({ isNavigate, isLocallyError = false }) {
  const { setUserInfo, setAuthToken, authToken } = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const handleLogOut = () => {
    setAuthToken(null);
    setUserInfo(null);
    localStorage.removeItem("keeperAuthToken");
    navigate("/");
  };
  const navigate = useNavigate();

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <>
      <div
        className={`flex flex-col items-center justify-center bg-white px-4 py-8 ${
          isLocallyError ? "h-[calc(100vh-4rem)]" : "min-h-screen"
        }`}
      >
        <div className="flex flex-col items-center p-4">
          <img src={warningImage} alt="Error Icon" className="mb-4" />

          <div className="space-y-5 text-center">
            <h1 className="text-5xl font-bold">Oops!</h1>

            <p className="text-lg font-bold">Something went wrong</p>

            <PrimaryButton
              action={isNavigate ? () => navigate("/") : handleReloadClick}
              customStyle={"!py-4 !px-1 !mb-5"}
            >
              {isNavigate ? "Back to Home" : "Please Try Again"}
            </PrimaryButton>
          </div>

          {!isLocallyError ? (
            <div className="text-center">
              <p className="text-sm mb-2">
                Still facing issue?{" "}
                <span
                  className="text-primary underline cursor-pointer"
                  onClick={() => (authToken ? setIsOpen(true) : navigate("/"))}
                >
                  {authToken ? "Click here to Logout" : "Go back to Home"}
                </span>
              </p>

              <p className="text-xs">OR</p>

              <Link
                to={"tel:+918439309979"}
                className="flex justify-center items-center gap-x-2 text-primary"
              >
                <FaPhoneAlt className="text-lg " />{" "}
                <span className="underline">Click here to Call us</span>
              </Link>
            </div>
          ) : undefined}
        </div>
      </div>
      <LogoutConfirmation
        action={handleLogOut}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
}
