import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import HRMSLogin from "../../Pages/HRMSLogin/HRMSLogin";

export default function ReauthenticateUser({
  requireCredential,
  organisation,
}) {
  const { data: employerList } = useFetch("/auth/fetchEmployerList", {
    skip: !requireCredential,
  });
  const [openReauthenticating, setOpenReauthenticating] = useState(false);

  // find hrms data of the employer
  const employerData =
    employerList?.list?.find(
      (employer) =>
        employer?.employerName?.toLowerCase() === organisation?.toLowerCase()
    ) || {};

  // if user is not authenticated, pop up re-authentication dialog
  useEffect(() => {
    if (requireCredential) {
      setOpenReauthenticating(true);
    }
  }, [requireCredential]);
  return (
    <>
      <CustomDialog open={openReauthenticating}>
        <div className="bg-white p-3 pt-4 px-5 rounded-lg">
          <HRMSLogin
            selectedEmployer={employerData}
            isReauthenticate
            setOpenReauthenticating={setOpenReauthenticating}
          />
        </div>
      </CustomDialog>
    </>
  );
}

function CustomDialog({ open, children }) {
  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [open]);
  return (
    <>
      <div
        className={`relative  ${
          open
            ? "ease-out duration-300 opacity-100 z-[9999]"
            : "ease-in duration-200 opacity-0 -z-10"
        }`}
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-black/50 transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`flex min-h-full items-center justify-center ${
              open
                ? "ease-out duration-300 opacity-100 translate-y-0 sm:scale-100"
                : "ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }`}
          >
            <div className="relative transform overflow-hidden transition-all w-full max-w-[412px] p-5">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
