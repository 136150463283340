import React, { useEffect } from "react";

const calculatePercentage = (value, max) => {
  const parseValue = parseFloat(value.toFixed(1));
  const percentage = (parseValue / max) * 100;
  return parseInt(percentage);
};
export default function AnimatedRangeSlider({
  max,
  value,
  className,
  isProgress,
  setValue,
  interval = 2000,
}) {
  // increase progress bar percentage 5% in every 2s
  useEffect(() => {
    if (isProgress && value <= 90) {
      const progressTimer = setInterval(() => {
        if (value === 5) {
          setValue((prev) => prev + 50);
        }
        setValue((prev) => prev + 5);
      }, interval);
      return () => clearInterval(progressTimer);
    }
  }, [value, isProgress, setValue, interval]);
  return (
    <>
      <div className={`relative w-full h-2 mt-8 ${className}`}>
        <div className="w-full h-full bg-[#EFE6FF] rounded-full"></div>
        <div
          className={`w-[${calculatePercentage(
            value,
            max
          )}%] h-full bg-primary rounded-full absolute top-0 left-0 transition-all duration-[2000ms]`}
          style={{ width: `${calculatePercentage(value, max)}%` }}
        ></div>
      </div>
    </>
  );
}
