import { useCallback, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import FixedDiv from "../../Components/FixedDiv/FixedDiv";
import { PrimaryButton } from "../../Components/PrimaryButton/PrimaryButton";
import AnimatedRangeSlider from "../../Components/RangeSlider/AnimatedRangeSlider/AnimatedRangeSlider";
import TipsDisplay from "../../Components/TipsDisplay/TipsDisplay";
import { DataContext } from "../../Context/DataProvider";
import instance from "../../utils/axiosInstance";
import HRMSPageBanner from "./Section/HRMSPageBanner";

const tipsList = [
  "Please wait while we connect with your HRMS",
  "Please do not refresh or press the back button",
];

// abp network format instructions
const abpUserNameFormat = [
  {
    title: "Correct username format",
    formatText: ["rajveers"],
    isFormatCorrect: true,
  },
  {
    title: "Incorrect username format",
    formatText: ["MC-8739", "rajveers@abpnetwork.com"],
    isFormatCorrect: false,
  },
];
export default function HRMSLogin({
  selectedEmployer,
  setEmployerSelectionStatus,
  isReauthenticate = false,
  setOpenReauthenticating,
}) {
  const { setAuthToken, metaData, setRefetchMetaVersion } =
    useContext(DataContext);
  const { employeeProfile } = metaData || {};
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [status, setStatus] = useState("initial");
  const [isAuthenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [progressPercentage, setProgressPercentage] = useState(5);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const screenHeight = window.innerHeight;

  // check abp network employee
  const isABPNetwork = selectedEmployer?.employerName?.includes("ABP") || false;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userName && password) {
      setStatus("loading");
      saveHRMSData("LOGIN");
    }
  };

  // get form value
  const getFormValue = useCallback(() => {
    const employerHRMSInfo = {
      username: userName,
      password,
      employerId: selectedEmployer?.id,
      hrmsId: selectedEmployer?.hrmsId,
    };
    return employerHRMSInfo;
  }, [selectedEmployer, userName, password]);

  // handle hrms login failed response
  const handleFailedResponse = useCallback(() => {
    setStatus("failed");
    // setEmployerSelectionStatus("initial");
    toast.error("Login failed. Please try again");
  }, []);

  // verify user authentication
  const handleVerifyUser = useCallback(async () => {
    try {
      const { data } = await instance.post(
        "/general/setCredentialsAsVerified",
        {
          employeeId: employeeProfile?.id,
        }
      );
      return data;
    } catch (error) {
      setStatus("failed");
      console.log(error);
    }
  }, [employeeProfile]);

  // handle hrms login success/failed response
  const handleResponse = useCallback(
    async (data) => {
      const responseStatus = data?.status?.toLowerCase();

      if (responseStatus === "success") {
        setProgressPercentage(100);
        setTimeout(() => {
          const auth = data?.jwt;
          setAuthToken(auth);
          localStorage.setItem("keeperAuthToken", JSON.stringify(auth));
          if (isReauthenticate) {
            handleVerifyUser().then((verifyStatus) => {
              if (verifyStatus?.status) {
                setStatus("success");
                toast.success("Re-authentication successful");
                setOpenReauthenticating(false);
                setRefetchMetaVersion((prev) => prev + 1);
              } else {
                setStatus("failed");
              }
            });
          } else {
            setStatus("success");
            navigate("/pan-verification");
          }
        }, 1000);
      }

      if (responseStatus === "failed") {
        handleFailedResponse();
      }
    },
    [
      navigate,
      setAuthToken,
      handleFailedResponse,
      handleVerifyUser,
      isReauthenticate,
      setRefetchMetaVersion,
      setOpenReauthenticating,
    ]
  );

  // stored hrms data in database
  const saveHRMSData = useCallback(
    async (apiType) => {
      const employerHRMSInfo = getFormValue();
      employerHRMSInfo.type = apiType;
      try {
        const res = await instance.post("/auth/saveHrmsData", employerHRMSInfo);
        if (res?.status === 200) {
          const responseStatus = res?.data?.status?.toLowerCase();
          if (responseStatus === "success" || responseStatus === "pending") {
            handleResponse(res?.data);
            setAuthenticated(true);
          } else if (responseStatus === "failed") {
            handleFailedResponse();
          }
        }
      } catch (error) {
        console.log(error);
        setStatus("error");
      }
    },
    [getFormValue, handleResponse, handleFailedResponse]
  );

  // set username from employee profile
  useEffect(() => {
    setUserName(employeeProfile?.username || "");
  }, [employeeProfile]);

  const STATUS_CHECKING_CONDITION = status === "loading" && isAuthenticated;

  // call /auth/saveHRMSData api every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (STATUS_CHECKING_CONDITION) {
        saveHRMSData("STATUS");
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [saveHRMSData, STATUS_CHECKING_CONDITION]);

  return (
    <>
      <div
        className={`${
          status === "loading"
            ? `flex flex-col justify-center py-10 h-[calc(100vh-36px)] px-5 ${
                isReauthenticate && "h-full"
              }`
            : ""
        }`}
      >
        {status === "loading" ? (
          <div>
            <HRMSPageBanner
              status={status}
              selectedEmployer={selectedEmployer}
            />
            <AnimatedRangeSlider
              value={progressPercentage}
              max={100}
              setValue={setProgressPercentage}
              isProgress={STATUS_CHECKING_CONDITION}
            />
            <div className="text-center mt-5">
              <TipsDisplay
                tipsList={tipsList}
                isDisplay={STATUS_CHECKING_CONDITION}
              />
            </div>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            className={`flex flex-col justify-between py-10 pt-20 h-[calc(100vh-36px)] ${
              screenHeight < 700 && "!h-full gap-20"
            }
                ${isReauthenticate && "!h-full !py-5 !pt-0 !gap-5"}`}
          >
            <div>
              {isReauthenticate ? (
                <h1 className="text-center text-xl font-semibold pb-14">
                  Re-authenticate your account
                </h1>
              ) : undefined}
              <HRMSPageBanner selectedEmployer={selectedEmployer} />
              <div
                className="mt-7
            mb-2 space-y-5"
              >
                <div className="space-y-2">
                  <label htmlFor="username">
                    {isABPNetwork ? "ABP World" : selectedEmployer?.hrmsName}{" "}
                    Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    placeholder="Enter Username"
                    className={`block w-full rounded-lg border border-gray-300 focus:border-gray-800 focus:outline-none focus:ring-0 py-3 pl-3 text-gray-500`}
                    required
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="password">
                    {isABPNetwork ? "ABP World" : selectedEmployer?.hrmsName}{" "}
                    Password
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Enter Password"
                      className={`block w-full rounded-lg border border-gray-300 focus:border-gray-800 focus:outline-none focus:ring-0 py-3 pl-3 text-gray-500`}
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {password?.length ? (
                      <PasswordToggle
                        setPasswordVisible={setPasswordVisible}
                        isPasswordVisible={isPasswordVisible}
                      />
                    ) : undefined}
                  </div>
                </div>
              </div>
              {status === "failed" && (
                <span className="mt-5 block text-sm text-red-500">
                  Your username or password was incorrect. Please try again.
                </span>
              )}
              {isABPNetwork && !isReauthenticate && (
                <div
                  className={`space-y-2 text-xs ${
                    status === "failed" ? "mt-3" : "mt-7"
                  }`}
                >
                  {abpUserNameFormat?.map(
                    ({ title, isFormatCorrect, formatText }, i) => (
                      <div key={title + i} className="space-y-1">
                        <h5 className="font-semibold">{title}</h5>
                        {formatText?.map((formatText, i) => (
                          <div
                            className="flex items-center gap-x-1"
                            key={formatText + i}
                          >
                            {isFormatCorrect ? (
                              <FaCheck className="text-sm text-green-600" />
                            ) : (
                              <IoClose className="text-sm text-red-600" />
                            )}
                            <span>{formatText}</span>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>

            {!isReauthenticate ? (
              <FixedDiv>
                <div className="mb-5 space-y-4 text-gray-600">
                  <p className="text-xs">
                    By proceeding you agree with Keeper’s{" "}
                    <span className="text-primary">
                      <Link to={"/terms"}>terms and condition</Link> and{" "}
                      <Link to="/privacy-policy">privacy policy</Link>
                    </span>
                  </p>
                </div>

                <PrimaryButton
                  customStyle={"!rounded-3xl !py-3 !mb-5"}
                  disabledCondition={!userName || !password}
                >
                  Continue
                </PrimaryButton>
              </FixedDiv>
            ) : (
              <PrimaryButton
                customStyle={"!rounded-full"}
                disabledCondition={!userName || !password}
              >
                Continue
              </PrimaryButton>
            )}
          </form>
        )}
      </div>
    </>
  );
}

const PasswordToggle = ({ setPasswordVisible, isPasswordVisible }) => {
  return (
    <span
      onClick={() => setPasswordVisible((prev) => !prev)}
      className="absolute right-3 top-[50%] -translate-y-[50%] text-sm bg-transparent text-primary cursor-pointer"
    >
      {isPasswordVisible ? "hide" : "show"}
    </span>
  );
};
